import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import { withStyles } from "@material-ui/core";
import { Box, Typography, Button, TextField, Toolbar, IconButton, MenuItem } from "@mui/material";
import Grid from "@material-ui/core/Grid/Grid";
import ArrowBack from '@mui/icons-material/ArrowBack';
import SectionPaper from "../../common/SectionPaper";
import "./styles.css";
import styles from "./styles";
import { messageShow } from "../../common/api/actions";
import { createOrUpdateCms } from '../api/actions/mutation';
import { getCms, getCmsById } from "../api/actions/query";

const FontSizeStyle = Quill.import("attributors/style/size");
FontSizeStyle.whitelist = ["10px", "12px", "14px", "18px", "24px", "36px", "48px"];
Quill.register(FontSizeStyle, true);

class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      title: "",
      content: "",
      selectedTag: "",
      usedTags: [],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { cmsId },
      },
    } = this.props;

    if (cmsId) {
      this.getCms(cmsId);
    }
    this.getUsedTags();
  }

  getCms = async (cmsId) => {
    const { messageShow, getCmsById } = this.props;

    try {
      const { data } = await getCmsById({ cmsId });
      if (data.success) {
        this.setState({
          _id: data.data._id,
          title: data.data.title,
          content: data.data.content,
          selectedTag: data.data.tag.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase()),
        });
      }
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };

  getUsedTags = async () => {
    const { messageShow, getCms } = this.props;
    try {
      const response = await getCms();
      //const usedTags = response.data.data.map((cms) => cms.tag);
      const usedTags = response.data.data.map((cms) => cms.tag.toUpperCase());
      this.setState({ usedTags });
    } catch (error) {
      messageShow("There was some error. Please try again.");
    }
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleContentChange = (value) => {
    this.setState({ content: value });
  };

  handleTagChange = (event) => {
    this.setState({ selectedTag: event.target.value });
  };

  handleSave = async () => {
    const { _id, title, content, selectedTag, usedTags } = this.state;
    const { messageShow, createOrUpdateCms, history } = this.props;
    const {
      match: {
        params: { cmsId },
      }
    } = this.props;

    if (!title.trim()) {
      messageShow("Title cannot be empty!");
      return;
    }

    if (!selectedTag.trim()) {
      messageShow("Tag cannot be empty!");
      return;
    }

    if (!content.trim()) {
      messageShow("Content cannot be empty!");
      return;
    }

    const formattedTag = selectedTag.replace(/\s+/g, '_').toUpperCase();
    if (!cmsId && usedTags.includes(formattedTag)) {
      messageShow("This tag has already been used. Please select a different tag.");
      return;
    }

    try {
      const sanitizedContent = DOMPurify.sanitize(content);
      const formattedTag = selectedTag.replace(/\s+/g, '_').toUpperCase();

      const cmsData = {
        _id,
        title,
        content: sanitizedContent,
        tag: formattedTag,
      };
      const response = await createOrUpdateCms(cmsData);
      if (response.data.success) {
        messageShow(response.data.message);
        this.setState({
          title: "",
          content: "",
          selectedTag: "",
        });
        history.push("/cms");
      } else {
        messageShow("Failed to save content. Please try again.");
      }
    } catch (error) {
      console.error("Error saving content:", error);
      messageShow("An error occurred while saving content.");
    }
  };

  handleClear = () => {
    this.setState({ title: "", content: "", selectedTag: "" });
  };

  render() {
    const { title, content, selectedTag } = this.state;
    const {
      match: {
        params: { cmsId },
      },
      classes,
    } = this.props;

    const toolbarOptions = [
      [{ size: ["10px", "12px", "14px", "18px", "24px", "36px", "48px"] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
    ];

    const modules = {
      toolbar: toolbarOptions,
    };

    const tags = [
      "Headers",
      "About Us",
      "Terms & Conditions",
      "Privacy Policy",
      "App Store Message Panel",
      "Cancellations",
      "Returns",
      "Refunds"
    ];

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={() => this.props.history.push("/cms")}
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            {cmsId ? "Edit" : "Create"} Template
          </Typography>
        </Toolbar>
        <Grid item xs={12} lg={6}>
          <SectionPaper padding>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <TextField
                label="Title"
                value={title}
                onChange={this.handleTitleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <TextField
                select
                label="Tag"
                value={selectedTag || ""}
                onChange={this.handleTagChange}
                fullWidth
                margin="normal"
                variant="outlined"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Tag
                </MenuItem>
                {tags.map((tag, index) => (
                  <MenuItem key={index} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <ReactQuill
                value={content}
                onChange={this.handleContentChange}
                theme="snow"
                style={{ height: "350px", paddingBottom: "40px" }}
                modules={modules}
              />
            </Grid>
            <Box display="flex" justifyContent="flex-start" mt={4} mb={4} gap={2}>
              <Button variant="contained" color="primary" onClick={this.handleSave}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" onClick={this.handleClear}>
                Clear
              </Button>
            </Box>
          </SectionPaper>
        </Grid>
      </div>
    );
  }
}

CreateOrUpdate.propTypes = {
  messageShow: PropTypes.func.isRequired,
  createOrUpdateCms: PropTypes.func.isRequired,
  getCmsById: PropTypes.func.isRequired,
  getCms: PropTypes.func.isRequired,
};

export default connect(null, { messageShow, createOrUpdateCms, getCmsById, getCms })(
  withRouter(withStyles(styles)(CreateOrUpdate))
);