import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { messageShow } from '../../common/api/actions.js';
import * as query from '../api/action/query.js';
import * as mutation from '../api/action/mutation.js';

const useEvents = (eventType, refresh, newEvent, inProgressEventId, setInProgressEventId) => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const fetchEvents = useCallback(async () => {
        setLoading(true);
        try {
            let eventsData;
            switch (eventType) {
                case 'email':
                    eventsData = await query.fetchEmails();
                    break;
                case 'push-notification':
                    eventsData = await query.fetchNotification();
                    break;
                case 'fb-post':
                    eventsData = await query.fetchPosts();
                    break;
                default:
                    throw new Error('Invalid event type');
            }

            if (eventsData && eventsData.error) {
                dispatch(messageShow(eventsData.error));
            } else {
                eventsData.sort((a, b) => new Date(a.date) - new Date(b.date));
                setEvents(eventsData);
            }
        } catch (err) {
            dispatch(messageShow(err.message || "An unknown error occurred while fetching events"));
        } finally {
            setLoading(false);
        }
    }, [eventType, dispatch]);

    useEffect(() => {
        fetchEvents();
    }, [refresh]);

    useEffect(() => {
        if (newEvent) {
            setEvents((prevEvents) => {
                const updatedEvents = [newEvent, ...prevEvents];
                return updatedEvents.map(event => ({
                    ...event,
                    status: inProgressEventId ? "Disabled" : event.status || "Yet to Send"
                }));
            });
        }
    }, [newEvent, inProgressEventId]);

    const sendFunction = async (id) => {
        try {
            let response;
            switch (eventType) {
                case 'email':
                    response = await mutation.sendEmail(id, "email");
                    break;
                case 'push-notification':
                    response = await mutation.sendNotification(id);
                    break;
                case 'fb-post':
                    response = await mutation.sendFbPost(id);
                    break;
                default:
                    throw new Error('Invalid event type');
            }
            return response;
        } catch (error) {
            console.error("Error in sendFunction:", error);
            return { message: "Failed", error: error.message || "An error occurred while sending" };
        }
    };

    const handelCancel = async (id) => {
        try {
            const response = await mutation.cancelSend(id);
            if (response && !response.error) {
                setInProgressEventId(null);
            }
            return response;
        } catch (error) {
            dispatch(messageShow(error.message || "An error occurred while cancelling"));
            return null;
        }
    };

    const refreshFunction = async (id) => {
        try {
            let response;
            switch (eventType) {
                case 'email':
                    response = await query.handleRefreshEmail(id);
                    break;
                case 'push-notification':
                    response = await query.handleRefreshNotification(id);
                    break;
                case 'fb-post':
                    response = await query.handleRefreshPosts(id);
                    break;
                default:
                    throw new Error('Invalid event type');
            }
            if (response && (response.status === "Completed" || response.status === "Cancelled" || response.status === "Failed")) {
                setInProgressEventId(null);
            }
            return response;
        } catch (error) {
            dispatch(messageShow(error.message || "An error occurred while refreshing"));
            return null;
        }
    };

    const deleteFunction = async (ids) => {
        try {
            let response;
            switch (eventType) {
                case 'email':
                    response = await mutation.deleteEvent(ids);
                    break;
                case 'push-notification':
                    response = await mutation.deleteNotification(ids);
                    break;
                case 'fb-post':
                    response = await mutation.deletePost(ids);
                    break;
                default:
                    throw new Error('Invalid event type');
            }
            return response;
        } catch (error) {
            dispatch(messageShow(error.message || "An error occurred while deleting"));
            return null;
        }
    };

    const handleDeleteEvent = async (ids) => {
        try {
            const response = await deleteFunction(ids);
            if (response && response.error) {
                dispatch(messageShow(response.error));
                return;
            }
            setEvents((prevEvents) => prevEvents.filter((event) => !ids.includes(event._id)))
            const message = ids.length>1?"Events deleted successfully" : "Event deleted successfully"
            dispatch(messageShow(message))
        } catch (error) {
            dispatch(messageShow(error.message || "An error occurred while deleting the event"));
        }
    };

    const updateEvent = (newEvent) => {
        setEvents(prevEvents => {
            const updatedEvents = [newEvent, ...prevEvents];
            return updatedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
        });
    };
    
    return {
        events,
        setEvents,
        isLoading,
        handleDeleteEvent,
        fetchEvents,
        refreshFunction,
        sendFunction,
        handelCancel,
        updateEvent
    };
};

export default useEvents;