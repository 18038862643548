const styles = theme => ({
    grow: {
        flexGrow: 1
    },

    toolbar: {
      borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
    },
  
    menuButton: {
      marginLeft: -12
    }
  })
  
  export default styles
  