import axios from 'axios'
import { API_URL } from '../../../../setup/config/env'

export function createOrUpdateCms(cms) {
    return dispatch => {
      return axios.post(API_URL+"/api/cms/createOrUpdate", {...cms})
    }
}
  
export function removeCms({ cmsId }) {
    return dispatch => {
      return axios.post(API_URL+"/api/cms/delete", {"_id":cmsId})
    }
}
