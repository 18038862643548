// App Imports
import params from '../../../setup/config/params'
import List from '../List'
import CreateOrUpdate from '../CreateOrUpdate'

// Pages routes
const routes = {
  List: {
    path: '/cms',
    component: List,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  cmsCreate: {
    path: '/cms/create',
    component: CreateOrUpdate,
    exact: true,
    auth: true,
    role: params.user.roles.admin.key
  },
  cmsEdit: {
    path: (cmsId = ':cmsId') => (`/cms/edit/${ cmsId }`),
    component: CreateOrUpdate,
    auth: true,
    role: params.user.roles.admin.key
  },
  
}
export default routes;