import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import routes from "../api/routes";
import { messageShow } from "../../common/api/actions";
import Loading from "../../common/Loading";
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { Button, Toolbar, Typography, Tooltip, Modal, Box, IconButton, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from "@mui/icons-material/Close";
import { getCms } from '../api/actions/query';
import { removeCms } from "../api/actions/mutation"

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsData: [],
      isLoading: true,
      activeKey: '0',
      openModal: false,
      modalContent: ''
    };
  }

  componentDidMount() {
    this.fetchCmsData();
  }

  fetchCmsData = async () => {
    const { getCms } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await getCms();
      if (response.data.success && response.data.data) {
        this.setState({
          cmsData: response.data.data,
        });
      }
    } catch (error) {
      console.error('Error fetching CMS data:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async (cmsId) => {
    const { messageShow, removeCms } = this.props;

    const confirmDelete = window.confirm(
      `Are you sure you want to delete this template?`
    );

    if (!confirmDelete) {
      return;
    }

    try {
      const response = await removeCms({ cmsId });
      if (response.data.success) {
        messageShow(response.data.message);
        this.fetchCmsData();
      }
    } catch (error) {
      console.error("Error deleting template:", error);
      messageShow("Error deleting template")
    }
  };

  handleAccordionToggle = (key) => {
    this.setState({ activeKey: key });
  };

  handlePreview = (content) => {
    this.setState({
      showModal: true,
      previewContent: content,
    });
  };

  handleOpenModal = (content) => {
    this.setState({ openModal: true, modalContent: content });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, modalContent: '' });
  };

  render() {
    const { cmsData, isLoading, activeKey, openModal, modalContent } = this.state;
    const { classes } = this.props;

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2
    };

    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Content Management System
          </Typography>

          <Link to={routes.cmsCreate.path}>
            <Tooltip title="Create Template" arrow>
              <Button size='big' color="primary" startIcon={<AddIcon />}></Button>
            </Tooltip>
          </Link>
        </Toolbar>
        <section id="menu" className="mt-4">
          <div className="clearfix mb-5 pb-5">
            <Container fluid>
              <Row className="mb-10 justify-content-center">
                <Col>
                  {isLoading ? (
                    <Loading /> 
                  ) : cmsData.length > 0 ? (
                    <Accordion
                      activeKey={activeKey}
                      onSelect={this.handleAccordionToggle}
                    >
                      {cmsData.map((item, index) => (
                        <Accordion.Item key={item._id} eventKey={index.toString()}>
                          <Accordion.Header>{item.title}</Accordion.Header>
                          <Accordion.Body className={`${classes.accordionBody}`}>
                            <span className={classes.content}>{item.content}</span>
                            <Tooltip title="Preview" arrow>
                              <IconButton
                                color="info"
                                onClick={() => this.handleOpenModal(item.content)}
                                sx={{ marginRight: '10px' }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                color="primary"
                                sx={{ marginRight: '10px' }}
                                component={Link}
                                to={`/cms/edit/${item._id}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                color="error"
                                onClick={() => this.handleDelete(item._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  ) : (
                    <Typography variant="h6" align="center">
                      No Content Found
                    </Typography>
                  )}
                </Col>

              </Row>
            </Container>
          </div>
        </section>

        <Modal open={openModal} onClose={this.handleCloseModal}>
          <Box sx={modalStyle}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="h2">
                Content Preview
              </Typography>
              <IconButton onClick={this.handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ my: 2, borderColor: 'rgba(0, 0, 0, 0.8)' }} />
            <Box
              sx={{
                maxHeight: 400,
                overflowY: 'auto',
                padding: '8px 0'
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  messageShow: PropTypes.func.isRequired,
  getCms: PropTypes.func.isRequired,
  removeCms: PropTypes.func.isRequired,
};

export default connect(null, { messageShow, getCms, removeCms })(withStyles(styles)(List));
