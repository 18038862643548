import axios from 'axios'
import { API_URL } from '../../../../setup/config/env'

export function getCms() {
    return dispatch => {
    return axios.get(API_URL+"/api/cms")
}
}

export function getCmsById({cmsId}) {
    return dispatch => {
    return axios.post(API_URL+"/api/cms",{_id: cmsId})
}
}