const styles = theme => ({
    grow: {
      flexGrow: 1
    },
  
    toolbar: {
      borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
    },

    accordionBody: {
      display: 'flex',
      alignItems: 'center', 
      position: 'relative',
      justifyContent: 'flex-start',  
    },
    
    content: {
      flexGrow: 1,
      overflow: 'hidden', 
    },
  })
  
  export default styles
  